.navbar-container {
  .navbar-contents {
    max-width: 1400px;
    margin: auto;
  }

  .nav-selected {
    color: #054c70;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .nav-item {
    color: #6c757d;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  @media only screen and (max-width: 1919px) {
    .navbar-contents {
      max-width: 1200px;
      margin: auto;
    }
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url('./assets/expand-icon.webp') !important;
  }

  .nav-hover {
    transition: all 0.5s;
  }

  .nav-hover:hover {
    color: #067eb9;
    transition: all 0.5s;
  }
}
