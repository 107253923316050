.payment-section {
  .payment-title-one {
    color: #054c70;
    font-family: 'Inter';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .payment-heading-message {
    color: #ffa14a;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form-control::placeholder {
    color: #747d8f;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 125% */
  }
  .app-input-lable {
    color: #054c70;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }

  .custom-form-control {
    border-radius: 6px;
    border: 1px solid #dee2e6;
    background: #fff;
  }

  .form-control:active,
  .form-control:focus,
  .form-control:focus:active {
    border-radius: 6px;
    border: 1px solid #dee2e6;
    background: #ffffff;
  }
  .text-error {
    color: #ff4c6c;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

@media only screen and (min-width: 1800px) {
  .payment-section {
    height: calc(100vh - 120px);
  }
}
