.success-header {
  color: #054c70;
  font-family: 'Inter';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.notice-message {
  color: #747d8f;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.transaction-text {
  color: #054c70;
  text-align: center;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notice-display {
  height: calc(100vh - 149px);
}
