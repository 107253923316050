.home-container {
  .title-one {
    color: #054c70;
    font-family: 'Inter';
    font-size: 85.44px;
    font-style: normal;
    font-weight: 500;
    line-height: 89px;
  }
  .title-two {
    color: #054c70;
    font-family: 'Inter';
    font-size: 85.44px;
    font-style: normal;
    font-weight: 700;
    line-height: 89px;
  }

  .details-text {
    color: #747d8f;
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .home-img {
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-width: 560px) {
  .home-container {
    .home-img {
      width: 400px;
      height: 400px;
    }
    .title-one {
      font-size: 80.44px;
    }
    .title-two {
      font-size: 80.44px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .home-container {
    .home-img {
      width: 300px;
      height: 300px;
    }

    .title-one {
      font-size: 75.44px;
    }
    .title-two {
      font-size: 75.44px;
    }
  }
}

@media only screen and (max-width: 335px) {
  .home-container {
    .home-img {
      width: 250px;
      height: 250px;
    }

    .title-one {
      font-size: 60.44px;
    }
    .title-two {
      font-size: 60.44px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .home-container {
    height: calc(100vh - 225px);
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1150px !important;
  }
}
