$theme-colors: (
  'primary': #0089e5,
  'secondary': #054c70,
  'background': #f8f9fa,
  'secondary-background': #d6ecfb,
  'yellow': #00c013,
  'white': #fff,
  'gray': #6c757d,
  'light-gray': #8d8d8d,
  'gray-2': #505d68,
  'gray-3': #a1aeb7,
  'danger': red,
  'alt': #2f3df8,
);

@import '~bootstrap/scss/bootstrap';

.fade-in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
